import React from 'react';
import './App.css';
import {X16Props} from "./pages/16x/16x";
import {Route, Routes} from "react-router-dom";
import {X42Props} from "./pages/X42/X42";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={ <X42Props />} />
            <Route path="/X42" element={ <X42Props />} />
            <Route path="/16x" element={ <X16Props />} />
        </Routes>
    </div>
  );
}

export default App;
