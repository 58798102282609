import {useEffect, useState} from "react";
import {PropsApi, PropsType} from "../../api/props.api";
import styles from './x16.module.scss'
import {LinearProgress, Pagination} from "@mui/material";
import {copyBuffer} from "../../utils";


export const X16Props = () => {
    const [props, setProps] = useState<PropsType[]>([])
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        PropsApi.getAllProps(page, 1000, '16x').then((r) => {
            setProps(r.items)
            setPageCount(r.totalPages)
            setLoading(false)
        })
        return () => {
            setLoading(true)
        }
    }, [page])

    const handlePageClick = (page: number) => {
        setPage(page)
    }
    return <div>
        <div className={styles.Pagination}>
            <Pagination count={pageCount} page={page} onChange={(event, page) => handlePageClick(page)}/>
        </div>
        {loading ? <LinearProgress/> : <div className={styles.CardWrapper}>
            {props.map((p) => <CardProps key={p.modelId} item={p}/>)}
        </div>}
        {!loading && <div className={styles.Pagination}>
            <Pagination count={pageCount} page={page} onChange={(event, page) => handlePageClick(page)}/>
        </div>}
    </div>

}

type CardProps = {
    item: PropsType
}
export const CardProps = ({item}: CardProps) => {
    const [id, setId] = useState('')
    const handleCopy = async () => {
        await copyBuffer(item.modelId)
        setId(item.modelId)
        setTimeout(() => {
            setId('')
        }, 2000)
    }
    return <div className={styles.Card}>
        <div className={styles.image}>
            <img src={item.preview_base64} alt={'props'}/>
        </div>
        <div className={styles.info}>
            <div>{item.owner_name}</div>
            <button className={styles.buttonCopy} disabled={!!id} onClick={handleCopy}>copy</button>
        </div>

    </div>
}
