import {AxiosInstance} from "./instance";

export type PropsType = {
    modelId: string
    owner_name: string
    preview_base64: string
}

export type PaginateType<T> = {
    items: T,
    page: number
    totalCount: number
    totalPages: number
    pageSize: number
}
export const PropsApi = {
    async getAllProps(page: number, pageSize: number, type: "16x" | "X42") {
        return AxiosInstance.get<PaginateType<PropsType[]>>('/public-props', {
            params: {
                page,
                pageSize,
                type
            }
        }).then((r) => r.data)
    }
}
